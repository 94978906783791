<template>
  <v-dialog v-model="opened" persistent max-width="570px">
    <v-card>
      <v-card-title>
        {{ $t('auction_purchase_modal_title', { name: item.name }) }}
      </v-card-title>
      <v-card-text>
        <template v-if="!success">
          <v-container v-if="!loading && !purchasable">
            <v-row>
              <v-col cols="12">
                <div class="modal-text">
                  {{ $t('auction_not_purchasable_modal_text') }}
                </div>
              </v-col>
            </v-row>
          </v-container>
          <v-container v-else-if="!loading && !checkout_html">
            <v-row>
              <v-col cols="12">
                <div class="modal-text">
                  {{ $t('auction_purchase_modal_text') }}
                </div>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <qty-selector v-model="qty_for_purchase" :label="$t('auction_purchase_modal_qty_field')" :min="item.minimum_saleable" :max="purchasable" />
              </v-col>
            </v-row>
            <v-divider class="divider mb-4" />
            <v-row class="totals">
              <v-col>
                {{ $t('auction_purchase_modal_total') }}:
              </v-col>
              <v-col>
                {{ total | toPrice }}
              </v-col>
            </v-row>
            <v-row class="payment-methods">
              <v-radio-group v-model="payment_method" class="methods-list" mandatory>
                <v-col v-for="method in payment_methods" :key="method.code">
                  <v-radio :value="method.code">
                    <template v-slot:label>
                      <v-img :src="method.image" />
                    </template>
                  </v-radio>
                </v-col>
              </v-radio-group>
              <v-col cols="12">
                <div class="modal-text" v-if="selectedPaymentMethod.text" v-html="$t(selectedPaymentMethod.text)" />
              </v-col>
            </v-row>
          </v-container>
          <v-container v-else>
            <loader in-block />
            <div v-show="false" id="payment-form-parent" v-html="checkout_html" />
          </v-container>
        </template>
        <template v-else>
          <v-container>
            <v-row>
              <v-col cols="12">
                <div class="modal-text">
                  {{ $t('auction_success_offline_modal_text') }}
                </div>
              </v-col>
            </v-row>
          </v-container>
        </template>
      </v-card-text>
      <v-card-actions>
        <v-btn color="red" text @click="closeDialog">
          {{ $t('close') }}
        </v-btn>
        <v-btn v-if="!loading && purchasable && !success" color="primary" text @click="submit">
          {{ $t('buy') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import QtySelector from '@/components/Core/Form/QtySelector'
import Loader from '@/components/Core/Loader'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'BuyGrowerLotModal',
  components: {
    QtySelector,
    Loader
  },
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      payment_methods: [
        { code: 'cash', image: require('@/assets/icons/cash.svg'), text: 'cash_payment_method_text' },
        { code: 'wire', image: require('@/assets/icons/transfer.svg'), text: 'wire_payment_method_text' },
        { code: 'online', image: require('@/assets/icons/card.svg'), text: '' },
        { code: 'crypto', image: require('@/assets/icons/crypto.svg'), text: '' },
      ],
      opened: false,
      purchasable: this.item.purchasable,
      qty_for_purchase: this.item.purchasable,
      payment_method: 'cash',
      checkout_html: null,
      loading: false,
      success: false
    }
  },
  computed: {
    ...mapGetters({
      token: 'user/getAccessToken'
    }),
    total () {
      return this.item.price * this.qty_for_purchase
    },
    selectedPaymentMethod () {
      return this.payment_methods.find(pm => pm.code === this.payment_method)
    }
  },
  methods: {
    ...mapActions({
      buyLot: 'auction/grower/buyLot',
      checkPurchasable: 'auction/grower/checkPurchasable',
      initCheckout: 'payments/initializeCheckout'
    }),
    submit () {
      if (['online', 'crypto'].includes(this.payment_method)) {
        this.buyOnlinePayment()
      } else {
        this.buyOfflinePayment()
      }
    },
    buyOnlinePayment () {
      this.loading = true
      this.buyLot({
        token: this.token,
        payload: {
          qty: this.qty_for_purchase,
          sale_id: this.item.id,
          payment_method: this.payment_method
        }
      })
        .then(result => {
          return this.initCheckout({
            amount: this.total,
            order_id: result.data.order.id
          })
        })
        .then(result => {
          this.checkout_html = result.payload
          return result
        })
        .finally(() => {
          let formParent = document.getElementById('payment-form-parent')
          if (formParent && formParent.children && formParent.children.length) {
            formParent.children[0].submit()
          }
          this.loading = false
        })
    },
    buyOfflinePayment () {
      this.loading = true
      this.buyLot({
        token: this.token,
        payload: {
          qty: this.qty_for_purchase,
          sale_id: this.item.id,
          payment_method: this.payment_method
        }
      })
        .then(() => {
          this.success = true
        })
        .finally(() => {
          this.loading = false
        })
    },
    openDialog () {
      this.opened = true
      if (!this.purchasable) {
        this.loading = true
        this.checkPurchasable({ sale_id: this.item.id })
          .then(result => {
            this.purchasable = result.payload.purchasable
            this.qty_for_purchase = result.payload.purchasable
          })
          .finally(() => {
            this.loading = false
          })
      }
    },
    closeDialog () {
      this.opened = false
      this.qty_for_purchase = 0
    }
  }
}
</script>
<style lang="scss">
.modal-text {
  color: #649187 !important;
}

.totals {
  color: #649187 !important;
  font-weight: 700;
  font-size: 18px;

  & > div:last-of-type {
    text-align: end;
    color: #009357 !important;
    font-weight: 500;
    font-size: 24px;
  }
}

.payment-methods {
  justify-content: center;

  & .v-input--radio-group--column .v-input--radio-group__input {
    flex-direction: row!important;
    align-items: center;
  }
}
</style>
